/* eslint-disable jsx-a11y/no-redundant-roles */
import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import {
	type InternalRoutes,
	LocaleLink,
} from '#app/components/locale-link.tsx'
import { Logo } from '#app/components/logo.tsx'
import { useCurrentLanguage } from '#app/hooks/use-current-language.tsx'

interface NavigationItems {
	name: string
	redirectUrl: InternalRoutes
}

interface NavigationMenu {
	services: NavigationItems[]
	support: NavigationItems[]
	legal: NavigationItems[]
}

const navigationItems = (t: TFunction): NavigationMenu => ({
	services: [
		{ name: t('services.chat'), redirectUrl: 'chat' },
		{ name: t('services.services'), redirectUrl: 'services' },
		{ name: t('services.blog'), redirectUrl: 'blogs' },
	],
	support: [
		{ name: t('support.pricing'), redirectUrl: 'pricing' },
		{ name: t('support.contactUs'), redirectUrl: 'contactWiser' },
		{ name: t('support.aboutUs'), redirectUrl: 'aboutWiser' },
	],
	legal: [
		{ name: t('legal.privacy'), redirectUrl: 'privacy' },
		{ name: t('legal.termsOfService'), redirectUrl: 'termsOfService' },
	],
})

interface FooterProps {
	isNotWhite: boolean
}

export const Footer = ({ isNotWhite }: FooterProps) => {
	const currentLanguage = useCurrentLanguage()
	const { t } = useTranslation('footer')

	const navigation = navigationItems(t)
	return (
		<footer
			className={`flex-grow ${isNotWhite ? '' : 'bg-white'}`}
			aria-labelledby="footer-heading"
		>
			<h2 id="footer-heading" className="sr-only">
				Footer
			</h2>
			<div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
				<div className="xl:grid xl:grid-cols-3 xl:gap-8">
					<LocaleLink route="homeNew" lang={currentLanguage}>
						<div className="h-auto w-[7rem] md:w-[10rem]">
							<Logo />
						</div>
					</LocaleLink>
					<div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
						<div className="md:grid md:grid-cols-2 md:gap-8">
							<div>
								<h3 className="text-sm font-semibold leading-6 text-gray-900">
									{t('services.services')}
								</h3>
								<ul role="list" className="mt-6 space-y-4">
									{navigation.services.map(item => (
										<li key={item.name}>
											<LocaleLink
												lang={currentLanguage}
												route={item.redirectUrl}
											>
												<p className="text-sm leading-6 text-gray-600 hover:text-gray-900">
													{item.name}
												</p>
											</LocaleLink>
										</li>
									))}
								</ul>
							</div>
							<div className="mt-10 md:mt-0">
								<h3 className="text-sm font-semibold leading-6 text-gray-900">
									{t('support.general')}
								</h3>
								<ul role="list" className="mt-6 space-y-4">
									{navigation.support.map(item => (
										<li key={item.name}>
											<LocaleLink
												lang={currentLanguage}
												route={item.redirectUrl}
											>
												<p className="cursor-pointer text-sm leading-6 text-gray-600 hover:text-gray-900">
													{item.name}
												</p>
											</LocaleLink>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="md:grid md:grid-cols-2 md:gap-8">
							<div>
								<h3 className="text-sm font-semibold leading-6 text-gray-900">
									{t('legal.legal')}
								</h3>
								<ul role="list" className="mt-6 space-y-4">
									{navigation.legal.map(item => (
										<li key={item.name}>
											<LocaleLink
												lang={currentLanguage}
												route={item.redirectUrl}
											>
												<p className="cursor-pointer text-sm leading-6 text-gray-600 hover:text-gray-900">
													{item.name}
												</p>
											</LocaleLink>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
