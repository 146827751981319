import React, { type ReactNode } from 'react'
import { LocaleLink } from '#app/components/locale-link.tsx'
import { Logo } from '#app/components/logo.tsx'
import { useCurrentLanguage } from '#app/hooks/use-current-language.tsx'
import { LanguageForm } from '../form/forms/global/LanguageForm.tsx'
import { Footer } from './footer/index.tsx'
import { MainMenu } from './index.tsx'

interface MenuContainerProps {
	showLogo: boolean
	showFooter: boolean
	showLanguageForm: boolean
	children?: ReactNode
	className?: string
}
const MenuContainerBase = ({
	showLogo,
	showFooter,
	showLanguageForm,
	children,
	className,
}: MenuContainerProps) => {
	const currentLanguage = useCurrentLanguage()
	return (
		<>
			{showLogo && (
				<div className="absolute left-0 top-0 -mt-5 ml-6 hidden md:block">
					<LocaleLink route="homeNew" lang={currentLanguage}>
						<div className="h-auto w-[8rem]">
							<Logo />
						</div>
					</LocaleLink>
				</div>
			)}
			<div
				className={`flex h-full w-full flex-col ${className ? className : ''}`}
			>
				<nav className="my-1 mt-5 flex w-full flex-row justify-end gap-2 pr-10">
					{showLanguageForm && <LanguageForm />}
					<MainMenu />
				</nav>
				{children && children}
				{showFooter && <Footer isNotWhite={false} />}
			</div>
		</>
	)
}

export const MenuContainer = React.memo(MenuContainerBase)
